import { useState } from 'react';
import { Button } from "primereact/button";
import { TemplateDataView } from "../Components/TemplateDataView";
import { OverlayPanelMenu } from "../Components/OverlayPanelMenu";
import { ENUM_ICONS } from "../Components/Icons";
import { getData, patchData } from "../feathers";
import { logDefault } from "../Utils/logger";
import { useFormik } from "formik";
import { Tag } from 'primereact/tag';
import { useCurrentUserIsAdmin } from '../Persistence/CurrentUserContext';
import { ENUM_DATAVIEWS } from '../Persistence/DataViewFilterContext';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { useTaskareaTagsList } from '../Persistence/TaskareaTagsContext';
import { TaskareaForm, emptyTaskarea, validateTaskarea } from './TaskareaForm';
import { useTaskareasList } from '../Persistence/TaskareasContext';


const CLASSNAME = 'TaskareaDataview'
const ENUM_DATAVIEWS_VALUE = ENUM_DATAVIEWS.TASKAREAS
export const TaskareaDataview = ({ handleStatusChange }) => {
	const isAdmin = useCurrentUserIsAdmin();
	const taskareaList = useTaskareasList();
	const taskareaTagsList = useTaskareaTagsList();
	const [displayTaskareaDialog, setDisplayTaskareaDialog] = useState(false);

	const patchTaskarea = async () => {
		const taskarea = { ...formikTaskareas.values };
		taskarea.tags = taskarea.tags.map(e => { return taskareaTagsList.find(p => p.alias.toLowerCase() === e.toLowerCase()) || { alias: e.toLowerCase() } })
		logDefault(CLASSNAME, 'info', 'patchTaskarea tags', taskarea.tags)
		await patchData('taskareas', taskarea).then(async () => {
			setDisplayTaskareaDialog(false)
		})
	}

	/** Taskareas */
	const formikTaskareas = useFormik({
		initialValues: { ...emptyTaskarea },
		validate: validateTaskarea,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const onEditTaskarea = async (id) => {
		await getData('taskareas', id).then((taskarea) => {
			taskarea.tags = taskarea.tags.map(e => e.alias)
			logDefault(CLASSNAME, 'info', 'onEditTaskarea taskarea:', taskarea)
			formikTaskareas.setValues(taskarea);
			setDisplayTaskareaDialog(true)
		})
	}
	const renderContextMenu = (rowData) => {
		const items = [{ label: 'Aufgabenbereich bearbeiten', icon: ENUM_ICONS.PENCIL, command: () => { onEditTaskarea(rowData.id) } },
		{ label: rowData.active ? 'Inaktiv setzen' : 'Aktiv setzen', icon: (rowData.active ? ENUM_ICONS.DEACTIVATE : ENUM_ICONS.REACTIVATE), command: () => { handleStatusChange(rowData, 'taskareas', 'Aufgabenbereich') } }]

		return <div>
			<OverlayPanelMenu items={items} />
		</div>
	}

	const renderTaskareaTags = (row) => {
		return row.tags.map(e => <Tag key={'taskareatag_' + e.id} className="mr-2 px-3" rounded value={e.alias} />)
	}

	const columnsTaskareas = [
		{ field: "name", sortable: true, filter: "name", header: "Bereich", className: 'w-20rem', body: (row) => <Tag className={"mr-2 px-3"} style={{ color: '#' + row.color, backgroundColor: '#' + row.backcolor }} rounded value={row.name} /> },
		{ field: "tags", sortable: true, filterField: "tagValues", filter: "tagValues", header: "Tags des Bereichs", className: '', body: renderTaskareaTags },
		{ field: "active", sortable: true, filter: "active", header: "Status", className: 'w-10rem', body: 'renderTag' },
		{ className: 'w-3rem', body: renderContextMenu },
	]

	return <div className='flex flex-column gap-2'>
		<div className='flex justify-content-end'>
			<Button className='' disabled={!isAdmin} label='Neuen Bereich anlegen' onClick={() => { formikTaskareas.setValues({ ...emptyTaskarea }); setDisplayTaskareaDialog(true) }} />
		</div>
		<TemplateDataView key='datatable-taskareas' scrollHeight={'calc(100vh - 240px)'} classNameDataTable='flex w-full' dataTableList={taskareaList} columns={columnsTaskareas} ENUM_DATAVIEWS_VALUE={ENUM_DATAVIEWS_VALUE} />
		<EditDialog
			form={<TaskareaForm formik={formikTaskareas} key='taskarea-form' />}
			formik={formikTaskareas}
			patchForm={patchTaskarea}
			header={formikTaskareas.values.id ? 'Aufgabenbereich ändern' : 'Aufgabenbereich neu anlegen'}
			message={formikTaskareas.values.id ? 'Hier können sie den Aufgabenbereich: ' + formikTaskareas.values.name + ' ändern.' : 'Hier können sie einen Aufgabenbereich neu anlegen'}
			setDisplayDialog={setDisplayTaskareaDialog}
			displayRegionDialog={displayTaskareaDialog}
		/>
	</div>
}